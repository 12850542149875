<template>
  <v-row 
    class="mt-0" 
    dense
  >
    <v-col cols="8">
      <v-autocomplete
        dense
        outlined
        no-filter
        hide-selected
        return-object
        persistent-hint
        class="rounded-lg body-2"
        v-model="price"
        :items="prices"
        :search-input.sync="priceSearch"
      ></v-autocomplete>
    </v-col>

    <v-col cols="3">
      <v-text-field
        dense
        outlined
        type="number"
        class="rounded-lg"
        v-model="quantity"
      ></v-text-field>
    </v-col>

    <!-- <v-col cols="2">
      <p 
        class="font-weight-bold pt-2"
      > 
        {{ amount }}
      </p>
    </v-col> -->

    <v-col 
      v-if="itemIndex > 0"
      cols="1"
    >
      <!-- <v-btn 
        icon  
        class="mt-1"
        color="error"
        @click="removeItem()"
      >
        <v-icon>
          mdi-trash-can
        </v-icon>
      </v-btn> -->
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: [
    'item',
    'itemIndex',
  ],

  data () {
    return {
      prices: [],
      quantity: 0,
      price: null,
      priceSearch: '',
    }
  },

  watch: {
    priceSearch (n, o) {
      const selectedPrice = this.prices.find((price) => price === this.price)

      if (n && n.length > 1 && selectedPrice?.text !== n) {
        this.searchPrice(n)
      }

      if (!n && o.length) {
        this.loadPrices()
      }
    },

    price (price) {
      let orderItems = this.orderItems
      orderItems[this.itemIndex].price_uid = price.price_uid
      this.setOrderItems(orderItems)
    },

    quantity (quantity) {
      let orderItems = this.orderItems
      orderItems[this.itemIndex].quantity = quantity
      this.setOrderItems(orderItems)
    }
  },

  computed: {
    ...mapGetters('retail', {
      distributionPointUid: 'getDistributionPointUid',
      orderItems: 'getOrderItems'
    }),

    amount () {
      if (!this.quantity || !this.price) {
        return 0
      }

      return Number(this.price.amount) * Number(this.quantity)
    }
  },

  methods: {
    ...mapActions([
      'setPrices',
    ]),

    ...mapActions('retail', [
      'setOrderItems'
    ]),

    searchPrice: _.debounce(function (search) {
      this.loadPrices(search)
    }, 700),

    loadPrices (search = null) {
      this.setPrices({
        params: {
          ...(search && { search }),
          distribution_point_uid: this.distributionPointUid,
        },
      }).then(({ data }) => {
        this.prices = data.filter(({ price_uid }) => {
          return !_.map(this.orderItems, 'price_uid').includes(price_uid)
        }).map((price) => ({
          text: price.product.description,
          price_uid: price.price_uid,
          amount: price.amount,
        }))
      })
    },

  //   removeItem () {
  //     const orderItems = this.order.order_items
  //     orderItems.splice(this.itemIndex, 1)
  //     this.setOrder({ order_items: orderItems })
  //   }
  },

  mounted () {
    this.loadPrices()
  }
}
</script>